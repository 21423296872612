class Logger {
  debug(msg) {
    console.log(
      '%c %s',
      'color:red; font-size: 14px; background-color: yellow',
      msg
    )
  }
}

const logger = new Logger()
export default logger
