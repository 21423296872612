<template>
  <div>
    <VChart
      class="chart"
      :option="option"
      :update-options="{ notMerge: true }"
      @click="onClickVChart"
    />
    <a-row justify="end">
      <a-col :span="2">
        <a-row type="flex" justify="start" align="middle">
          <a-space>
            <div class="pulse-icon"></div>
            <div>{{ $t('PR001.pulse') }}</div>
          </a-space>
        </a-row>
      </a-col>
      <a-col :span="4">
        <a-row type="flex" justify="start" align="middle">
          <a-space>
            <div class="bloodpress-warn-icon"></div>
            <div>{{ $t('PR001.pulseLowHigh') }}</div>
          </a-space>
        </a-row>
      </a-col>
      <a-col :span="4">
        <a-row type="flex" justify="start" align="middle">
          <a-space>
            <div class="bloodpress-normal-icon"></div>
            <div>{{ $t('PR001.pulseNormal') }}</div>
          </a-space>
        </a-row>
      </a-col>
    </a-row>

    <a-modal
      v-model:visible="isShowDetail"
      title=""
      class="detail-modal"
      width="860px"
      :footer="null"
      centered
    >
      <!-- <template #footer>
        <a-button class="btn-cancel-gray" @click="onClickDeleteCancel">
          {{ $t('LM005.cancel') }}
        </a-button>
        <a-button class="btn-confirm-green" @click="onClickDeleteOk">
          {{ $t('LM005.confirm') }}
        </a-button>
      </template>
    -->

      <a-table
        class="detail-table"
        :columns="detailColumns"
        :data-source="detailData"
        :rowKey="(record) => record.id"
        size="small"
      >
        <template #tmpRecordTimeTitle class="group1">
          <div class="cusTableTitle">{{ $t('PR001.recordTime') }}</div>
          <div class="cusTableUnit"></div>
        </template>
        <template #tmpRecordTime="{ text }">
          <span class="cusRecordTime">
            {{ convertTimeFormat(text) }}
          </span>
        </template>
        <template #tmpSystolicTitle>
          <div class="cusTableTitle">{{ $t('MS001.systolic') }}</div>
          <div class="cusTableUnit"></div>
        </template>
        <template #tmpDiastolicTitle>
          <div class="cusTableTitle">{{ $t('MS001.diastolic') }}</div>
          <div class="cusTableUnit">{{ $t('PR001.bloodPressureUnit') }}</div>
        </template>
        <template #tmpPulseTitle>
          <div class="cusTableTitle">{{ $t('PR001.pulse') }}</div>
          <div class="cusTableUnit">{{ $t('PR001.pulseUnit') }}</div>
        </template>
        <template #tmpArrTitle>
          <div class="cusTableTitle">{{ $t('PR001.arrTitle') }}</div>
          <div v-if="isTwLanguage()" class="cusTableUnit">ARR</div>
        </template>
        <template #tmpAfibTitle>
          <div class="cusTableTitle">{{ $t('PR001.afibTitle') }}</div>
          <div v-if="isTwLanguage()" class="cusTableUnit">AFib</div>
        </template>
        <template #tmpPcTitle>
          <div class="cusTableTitle">{{ $t('PR001.pcTitle') }}</div>
          <div v-if="isTwLanguage()" class="cusTableUnit">PC</div>
        </template>
        <template #tmpMathRoundCell="{ text }">
          <span class="cusTableCell">
            {{ roundDec(text, 0) }}
          </span>
        </template>
        <template #tmpMathRoundCellForSystolic="{ record, text }">
          <span
            class="cusTableCellNoColor"
            :class="{
              'normal-color': record?.systolicWarningLevel == 0,
              'warning-color': record?.systolicWarningLevel == 1,
              'abnormal-color': record?.systolicWarningLevel == 2,
            }"
          >
            {{ roundDec(text, 0) }}
          </span>
        </template>
        <template #tmpMathRoundCellForDiastolic="{ record, text }">
          <span
            class="cusTableCellNoColor"
            :class="{
              'normal-color': record?.diastolicWarningLevel == 0,
              'warning-color': record?.diastolicWarningLevel == 1,
              'abnormal-color': record?.diastolicWarningLevel == 2,
            }"
          >
            {{ roundDec(text, 0) }}
          </span>
        </template>
        <template #tmpArrCell="{ text }">
          <span class="cusTableCell">
            {{ convertArrValue(text) }}
          </span>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script setup>
  import { use } from 'echarts/core'
  import { CanvasRenderer } from 'echarts/renderers'
  import { CandlestickChart, LineChart } from 'echarts/charts'
  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    DataZoomComponent,
    GridComponent,
  } from 'echarts/components'
  import VChart, { THEME_KEY } from 'vue-echarts'
  import { ref, defineProps, watch, onMounted, computed } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { getMeasurementsById } from '@/api/measurement'
  import { useStore } from 'vuex'
  import logger from '@/utils/logger.js'
  import { roundDecimal } from '@/utils/pocketKnives'
  const dayjs = require('dayjs')

  use([
    CanvasRenderer,
    CandlestickChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    DataZoomComponent,
    GridComponent,
    LineChart,
  ])

  const { t, locale } = useI18n()
  const store = useStore()

  const getMeasureThreshold = () =>
    store.dispatch('measureThreshold/getMeasureThreshold')

  const measureThreshold = computed(
    () => store.state.measureThreshold.measureThreshold
  )

  // 將 api 取得的資料依據日期歸戶
  let collData = {}

  const isShowDetail = ref(false)

  const props = defineProps({
    refresh: {
      type: Number,
      default: 0,
    },
    startTs: {
      type: Number,
      default: 100,
    },
    endTs: {
      type: Number,
      default: 10,
    },
    fid: {
      type: String,
      default: '1110',
    },
  })

  let measurements = []
  // const showData = {
  //   date: ['7/1', '7/2'],
  //   candleData: [
  //     [
  //       88, // 平均舒張壓
  //       133, // 平均收縮壓
  //       143, // 最高收縮壓
  //       78, // 最低舒張壓
  //       '2023/01/01 15' // 用來 mouse over 時顯示
  //       'avg', // or 'one' ，單紀錄或平均值
  //     ],
  //   ],
  //   pulse: [88, 87],
  // }
  const showData = {
    date: [],
    candleData: [],
    pulse: [],
  }

  let isOneDayData = false

  const detailData = ref([])

  const detailColumns = ref([
    {
      //title: '量測時間',
      dataIndex: 'measured',
      key: 'measured',
      width: '80px',
      // fixed: 'left',
      align: 'center',
      customHeaderCell: () => {
        return {
          style: {
            background: '#eefbfc',
          },
        }
      },
      slots: {
        title: 'tmpRecordTimeTitle',
        customRender: 'tmpRecordTime',
      },
    },
    {
      //title: '舒張壓',
      dataIndex: 'systolic',
      key: 'systolic',
      slots: {
        title: 'tmpSystolicTitle',
        customRender: 'tmpMathRoundCellForSystolic',
      },
      width: '70px',
      align: 'center',
      customHeaderCell: () => {
        return {
          style: {
            background: '#e3f8f6',
          },
        }
      },
    },
    {
      //title: '收縮壓',
      dataIndex: 'diastolic',
      key: 'diastolic',
      slots: {
        title: 'tmpDiastolicTitle',
        customRender: 'tmpMathRoundCellForDiastolic',
      },
      width: '70px',
      align: 'center',
      customHeaderCell: () => {
        return {
          style: {
            background: '#e3f8f6',
          },
        }
      },
    },
    {
      //title: '脈搏',
      dataIndex: 'pulse',
      key: 'pulse',
      slots: {
        title: 'tmpPulseTitle',
        customRender: 'tmpMathRoundCell',
      },
      width: '70px',
      align: 'center',
      customHeaderCell: () => {
        return {
          style: {
            background: '#e3f8f6',
          },
        }
      },
    },
    {
      //title: '心律不整',
      dataIndex: 'arr',
      key: 'arr',
      slots: {
        title: 'tmpArrTitle',
        customRender: 'tmpArrCell',
      },
      width: '70px',
      align: 'center',
      customHeaderCell: () => {
        return {
          style: {
            background: '#e3f8f6',
          },
        }
      },
    },
    {
      //title: '心房震動',
      dataIndex: 'afib',
      key: 'afib',
      slots: {
        title: 'tmpAfibTitle',
        customRender: 'tmpArrCell',
      },
      width: '70px',
      align: 'center',
      customHeaderCell: () => {
        return {
          style: {
            background: '#e3f8f6',
          },
        }
      },
    },
    {
      //title: '早期收縮',
      dataIndex: 'pc',
      key: 'pc',
      slots: {
        title: 'tmpPcTitle',
        customRender: 'tmpArrCell',
      },
      width: '70px',
      align: 'center',
      customHeaderCell: () => {
        return {
          style: {
            background: '#e3f8f6',
          },
        }
      },
    },
  ])

  const pagination = ref({
    //position: 'top',
    current: 1,
    pageSize: 10,
    total: 0,
    showTotal: getTotalCountString,
  })

  function getTotalCountString(total) {
    return this.$t('HR001.totalCount') + ` ${total}`
  }

  const getMeasurements = async () => {
    const postBody = {
      startTs: props.startTs,
      endTs: props.endTs,
      fid: props.fid,
      pageNum: 1,
      pageSize: 20000,
      type: 'bloodPressure',
    }

    try {
      const data = await getMeasurementsById(postBody)
      if (data.ok === true && data?.data?.records) {
        measurements = data.data.records
      } else {
        measurements = []
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  }

  const updateData = () => {
    isOneDayData = false
    showData.date = []
    showData.candleData = []
    showData.pulse = []

    option.value.xAxis.data = showData.date
    option.value.series[0].data = showData.candleData
    option.value.series[1].data = showData.pulse

    if (measurements?.length <= 0) {
      return
    }
    if (!props.fid || props.startTs <= 0 || props.endTs <= 0) {
      return
    }

    // check same day
    const startDate = dayjs(props.startTs).format('YYYY/MM/DD')
    const endDate = dayjs(props.endTs).format('YYYY/MM/DD')

    if (startDate === endDate) {
      isOneDayData = true
      updateDataOneDay()
    } else {
      isOneDayData = false
      updateDataManyDay()
    }
    return
  }

  const updateDataManyDay = () => {
    const startDate = dayjs(props.startTs).format('YYYY/MM/DD')
    const endDate = dayjs(props.endTs).format('YYYY/MM/DD')

    // 1. 初始化圖表要用到的資料
    const mappingIndex = {}
    let startDay = dayjs(props.startTs).format('YYYY/MM/DD')
    const endDay = dayjs(props.endTs).format('YYYY/MM/DD')

    // 1.5 如果是少於 30 天，補到 30 天
    let checkDate = dayjs(props.endTs).subtract(30, 'days')
    if (checkDate.isBefore(dayjs(props.startTs))) {
      startDay = checkDate.format('YYYY/MM/DD')
    } else {
      checkDate = dayjs(props.startTs)
    }

    // logger.debug(startDay)
    // logger.debug(endDay)

    let count = 0

    showData.date.push(startDay)
    showData.candleData.push([])
    showData.pulse.push('-')
    mappingIndex[startDay] = count
    let retriveDateObj = checkDate.add(1, 'day')
    let retriveDay = retriveDateObj.format('YYYY/MM/DD')

    const ProtectCount = 370 // 預防無窮迴圈

    while (retriveDay !== endDay) {
      count++
      showData.date.push(retriveDay)
      showData.candleData.push([])
      showData.pulse.push('-')
      mappingIndex[retriveDay] = count
      if (count > ProtectCount) {
        break
      }

      retriveDateObj = retriveDateObj.add(1, 'day')
      retriveDay = retriveDateObj.format('YYYY/MM/DD')
    }
    count++
    showData.date.push(retriveDay)
    showData.candleData.push([])
    showData.pulse.push('-')
    mappingIndex[retriveDay] = count
    // console.log(showData)

    // 2. 整理 api 取得的資料
    collData = {}
    for (let record of measurements) {
      const measuredDay = dayjs(record.measured).format('YYYY/MM/DD')
      if (!collData[measuredDay]) {
        collData[measuredDay] = []
      }
      collData[measuredDay].push(record)
    }
    // console.log(collData)

    // 3. 計算資料
    for (const key in collData) {
      let avgDiastolic = 0 // 平均舒張壓
      let avgSystolic = 0 // 平均收縮壓
      let avgPulse = 0
      let countDiastolic = 0
      let countSystolic = 0
      let countPulse = 0
      let minDiastolic = 0 // 最小舒張壓
      let maxSystolic = 0 // 最大收縮壓
      for (const record of collData[key]) {
        if (!record.diastolic || record.diastolic <= 0) {
          break
        }
        if (minDiastolic === 0 || record.diastolic < minDiastolic) {
          minDiastolic = record.diastolic
        }
        if (record.systolic > maxSystolic) {
          maxSystolic = record.systolic
        }
        if (record.pulse > 0) {
          avgPulse += record.pulse
          countPulse++
        }
        if (record.diastolic > 0) {
          avgDiastolic += record.diastolic
          countDiastolic++
        }
        if (record.systolic > 0) {
          avgSystolic += record.systolic
          countSystolic++
        }
      }
      if (avgDiastolic > 0 && countDiastolic > 0) {
        avgDiastolic = avgDiastolic / countDiastolic
      }
      if (avgSystolic > 0 && countSystolic > 0) {
        avgSystolic = avgSystolic / countSystolic
      }
      if (avgPulse > 0 && countPulse > 0) {
        avgPulse = avgPulse / countPulse
      }
      const idx = mappingIndex[key]
      if (countDiastolic > 0 || countSystolic > 0) {
        // console.log(key)
        // console.log(idx)
        let showDate = ''
        let dataType = 'avg'
        if (countDiastolic === 1) {
          // 該天只有一筆
          showDate = dayjs(collData[key][0].measured).format('YYYY/MM/DD HH:mm')
          dataType = 'one'
        } else {
          // 該天有多筆
          showDate = dayjs(collData[key][0].measured).format('YYYY/MM/DD')
          dataType = 'avg'
        }

        if (
          avgSystolic - avgDiastolic <= 20 ||
          avgSystolic - avgDiastolic >= 60
        ) {
          // 脈壓差異常
          showData.candleData[idx] = [
            avgDiastolic,
            avgSystolic,
            maxSystolic,
            minDiastolic,
            showDate,
            dataType,
          ]
        } else {
          showData.candleData[idx] = [
            avgSystolic,
            avgDiastolic,
            maxSystolic,
            minDiastolic,
            showDate,
            dataType,
          ]
        }

        // console.log(showData.candleData[idx])
      }
      if (countPulse > 0) {
        showData.pulse[idx] = avgPulse
        // console.log(showData.pulse[idx])
      }

      // const showData = {
      //   date: ['7/1', '7/2'],
      //   candleData: [
      //     [
      //       133, // 平均收縮壓
      //       88, // 平均舒張壓
      //       143, // 最高舒張壓
      //       78, // 最低舒張壓
      //     ],
      //   ],
      //   pulse: [88, 87],
      // }
    }

    // 4. 塞資料到圖
    // option.value.xAxis.data = showData.date
    option.value.xAxis.data = showData.date.map(function (value, index, array) {
      let prefix = value.substring(0, 5) // 提取前五个字符
      if (prefix.length === 5) {
        return value.substring(5) // 去除前五个字符
      }
      return value
    })
    option.value.series[0].data = showData.candleData
    option.value.series[1].data = showData.pulse

    if (isBloodpressureMoreThan210()) {
      option.value.yAxis = [
        {
          type: 'value',
          scale: true,
          name: 'mmHg',
          max: 280,
          min: 40,
          boundaryGap: [0.2, 0.2],
          interval: 40,
        },
        {
          type: 'value',
          scale: true,
          name: 'BPM',
          max: 210,
          min: 30,
          boundaryGap: [0.2, 0.2],
          alignTicks: true,
        },
      ]
    } else {
      option.value.yAxis = [
        {
          type: 'value',
          scale: true,
          name: 'mmHg',
          max: 210,
          min: 30,
          boundaryGap: [0.2, 0.2],
        },
        {
          type: 'value',
          scale: true,
          name: 'BPM',
          max: 210,
          min: 30,
          boundaryGap: [0.2, 0.2],
          alignTicks: true,
        },
      ]
    }
  }
  const updateDataOneDay = () => {
    // 1. 初始化圖表要用到的資料
    // logger.debug(startDay)
    // logger.debug(endDay)

    for (let count = 0; count < 24; count++) {
      showData.date.push(count)
      showData.candleData.push([])
      showData.pulse.push('-')
    }
    // console.log(showData)

    // 2. 整理 api 取得的資料
    collData = new Array(24)
    for (var i = 0; i < collData.length; i++) collData[i] = []

    // console.log(collData)
    for (let record of measurements) {
      const measuredHour = dayjs(record.measured).hour()
      collData[measuredHour].push(record)
    }
    // console.log(collData)

    // 3. 計算資料
    for (let idx = 0; idx < collData.length; idx++) {
      let avgDiastolic = 0 // 平均舒張壓
      let avgSystolic = 0 // 平均收縮壓
      let avgPulse = 0
      let countDiastolic = 0
      let countSystolic = 0
      let countPulse = 0
      let minDiastolic = 0 // 最小舒張壓
      let maxSystolic = 0 // 最大收縮壓
      for (const record of collData[idx]) {
        // console.log(record)
        if (!record.diastolic || record.diastolic <= 0) {
          break
        }
        if (minDiastolic === 0 || record.diastolic < minDiastolic) {
          minDiastolic = record.diastolic
        }
        if (record.systolic > maxSystolic) {
          maxSystolic = record.systolic
        }
        if (record.pulse > 0) {
          avgPulse += record.pulse
          countPulse++
        }
        if (record.diastolic > 0) {
          avgDiastolic += record.diastolic
          countDiastolic++
        }
        if (record.systolic > 0) {
          avgSystolic += record.systolic
          countSystolic++
        }
      }
      if (avgDiastolic > 0 && countDiastolic > 0) {
        avgDiastolic = avgDiastolic / countDiastolic
      }
      if (avgSystolic > 0 && countSystolic > 0) {
        avgSystolic = avgSystolic / countSystolic
      }
      if (avgPulse > 0 && countPulse > 0) {
        avgPulse = avgPulse / countPulse
      }
      if (countDiastolic > 0 || countSystolic > 0) {
        // console.log(key)
        // console.log(idx)

        let showDate = ''
        let dataType = 'avg'
        if (countDiastolic === 1) {
          // 該小時只有一筆
          dataType = 'one'
          showDate = dayjs(collData[idx][0].measured).format('YYYY/MM/DD HH:mm')
        } else {
          // 該小時有多筆
          dataType = 'avg'
          showDate = dayjs(collData[idx][0].measured).format('YYYY/MM/DD')
        }
        if (
          avgSystolic - avgDiastolic <= 20 ||
          avgSystolic - avgDiastolic >= 60
        ) {
          // 脈壓差異常
          showData.candleData[idx] = [
            avgDiastolic,
            avgSystolic,
            maxSystolic,
            minDiastolic,
            showDate,
            dataType,
          ]
        } else {
          showData.candleData[idx] = [
            avgSystolic,
            avgDiastolic,
            maxSystolic,
            minDiastolic,
            showDate,
            dataType,
          ]
        }

        // console.log(showData.candleData[idx])
      }
      if (countPulse > 0) {
        showData.pulse[idx] = avgPulse
        // console.log(showData.pulse[idx])
      }

      // const showData = {
      //   date: ['7/1', '7/2'],
      //   candleData: [
      //     [
      //       133, // 平均收縮壓
      //       88, // 平均舒張壓
      //       143, // 最高舒張壓
      //       78, // 最低舒張壓
      //     ],
      //   ],
      //   pulse: [88, 87],
      // }
    }

    // 4. 塞資料到圖
    option.value.xAxis.data = showData.date
    option.value.series[0].data = showData.candleData
    option.value.series[1].data = showData.pulse

    if (isBloodpressureMoreThan210()) {
      option.value.yAxis = [
        {
          type: 'value',
          scale: true,
          name: 'mmHg',
          max: 280,
          min: 40,
          boundaryGap: [0.2, 0.2],
          interval: 40,
        },
        {
          type: 'value',
          scale: true,
          name: 'BPM',
          max: 210,
          min: 30,
          boundaryGap: [0.2, 0.2],
          alignTicks: true,
        },
      ]
    } else {
      option.value.yAxis = [
        {
          type: 'value',
          scale: true,
          name: 'mmHg',
          max: 210,
          min: 30,
          boundaryGap: [0.2, 0.2],
        },
        {
          type: 'value',
          scale: true,
          name: 'BPM',
          max: 210,
          min: 30,
          boundaryGap: [0.2, 0.2],
          alignTicks: true,
        },
      ]
    }
  }

  const isBloodpressureMoreThan210 = () => {
    return measurements.some((measurement) => measurement.systolic > 210)
  }

  function onClickVChart(inVal) {
    // console.log(inVal)

    if (isOneDayData) {
      const index = inVal.dataIndex || 0
      detailData.value = collData[index]
    } else {
      let clickDate = inVal?.data?.length >= 6 ? inVal.data[5] : null
      if (!clickDate) {
        return
      }
      clickDate = clickDate.substring(0, 10)
      detailData.value = collData[clickDate]
    }

    // console.log(detailData.value)
    isShowDetail.value = true
  }

  function roundDec(val, precision) {
    let convertNumber = val
    if (typeof val === 'string') {
      convertNumber = Number(val)
      if (isNaN(convertNumber)) {
        return 0
      }
    }
    return roundDecimal(convertNumber, precision)
  }

  function convertArrValue(text) {
    if (text === 0 || text === '0') {
      return '-'
    } else {
      return '✓'
    }
  }
  onMounted(async () => {
    if (props.fid && props.startTs > 0 && props.endTs > 0) {
      await getMeasurements()
      updateData()
    }
    getMeasureThreshold()
  })

  watch(
    () => props.refresh,
    async (current, prev) => {
      if (props.fid && props.startTs > 0 && props.endTs > 0) {
        await getMeasurements()
        updateData()
      }
    }
  )

  function convertTimeFormat(textTs) {
    if (textTs) {
      return dayjs(textTs).format('YYYY/MM/DD')
    } else {
      return ''
    }
  }

  //實作 isTwLanguage 方法
  function isTwLanguage() {
    return locale.value === 'tw'
  }

  const option = ref({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        animation: false,
        type: 'cross',
        lineStyle: {
          color: '#376df4',
          width: 2,
          opacity: 1,
        },
      },
      formatter: function (param) {
        if (param[0].seriesType == 'candlestick') {
          // console.log(param[0].data)
          // console.log(param)
          if (!param[0].data || param[0].data.length <= 1) {
            return ''
          }
          let systolicTitle =
            param[0].data[6] === 'avg' ? t('PR001.avgSYS') : t('MS001.systolic')
          let diastolicTitel =
            param[0].data[6] === 'avg'
              ? t('PR001.avgDIA')
              : t('MS001.diastolic')
          let pulseTitle =
            param[0].data[6] === 'avg' ? t('PR001.avgPullse') : t('PR001.pulse')

          if (param[0].data[6] !== 'avg') {
            // 單筆紀錄
            if (param[0].data[1] < param[0].data[2]) {
              // 脈壓差異常
              return [
                `${systolicTitle}: ${
                  param[0].data[2] ? param[0].data[2] : '-'
                } <br/> `,
                `${diastolicTitel}: ${
                  param[0].data[1] ? param[0].data[1] : '-'
                } <br/> `,
                `${pulseTitle}: ${param[1].data} <br/> `,
                `<hr size=1 style="margin: 3px 0">`,
                t('PR001.date') +
                  `: ${param[0].data[5] ? param[0].data[5] : ''} `,
                // `日期: ${param[0].name} `,
              ].join('')
            } else {
              return [
                `${systolicTitle}: ${
                  param[0].data[1] ? param[0].data[1] : '-'
                } <br/> `,
                `${diastolicTitel}: ${
                  param[0].data[2] ? param[0].data[2] : '-'
                } <br/> `,
                `${pulseTitle}: ${param[1].data} <br/> `,
                `<hr size=1 style="margin: 3px 0">`,
                t('PR001.date') +
                  `: ${param[0].data[5] ? param[0].data[5] : ''} `,
                // `日期: ${param[0].name} `,
              ].join('')
            }
          } else {
            // 平均紀錄
            if (param[0].data[1] < param[0].data[2]) {
              // 脈壓差異常
              return [
                `${systolicTitle}: ${
                  param[0].data[2] ? roundDecimal(param[0].data[2], 0) : '-'
                } <br/> `,
                `${diastolicTitel}: ${
                  param[0].data[1] ? roundDecimal(param[0].data[1], 0) : '-'
                } <br/> `,
                `${pulseTitle}: ${roundDecimal(param[1].data, 1)} <br/> `,
                t('PR001.highestSYS') +
                  `: ${param[0].data[3] ? param[0].data[3] : '-'} <br/> `,
                t('PR001.lowestDIA') +
                  `: ${param[0].data[4] ? param[0].data[4] : '-'} <br/> `,
                `<hr size=1 style="margin: 3px 0">`,
                t('PR001.date') +
                  `: ${param[0].data[5] ? param[0].data[5] : ''} `,
                // `日期: ${param[0].name} `,
              ].join('')
            } else {
              return [
                `${systolicTitle}: ${
                  param[0].data[1] ? roundDecimal(param[0].data[1], 0) : '-'
                } <br/> `,
                `${diastolicTitel}: ${
                  param[0].data[2] ? roundDecimal(param[0].data[2], 0) : '-'
                } <br/> `,
                `${pulseTitle}: ${roundDecimal(param[1].data, 1)} <br/> `,
                t('PR001.highestSYS') +
                  `: ${param[0].data[3] ? param[0].data[3] : '-'} <br/> `,
                t('PR001.lowestDIA') +
                  `: ${param[0].data[4] ? param[0].data[4] : '-'} <br/> `,
                `<hr size=1 style="margin: 3px 0">`,
                t('PR001.date') +
                  `: ${param[0].data[5] ? param[0].data[5] : ''} `,
                // `日期: ${param[0].name} `,
              ].join('')
            }
          }
        }
        // } else if (param[0].seriesType == 'bar') {
        //   return [
        //     '日期' + ': ' + param[1].name + '<hr size=1 style="margin: 3px 0">',
        //     '開盤價' + ': ' + self.CommaFormat(param[1].data[1]) + '<br/>',
        //     '最高價' + ': ' + self.CommaFormat(param[1].data[4]) + '<br/>',
        //     '最低價' + ': ' + self.CommaFormat(param[1].data[3]) + '<br/>',
        //     '收盤價' + ': ' + self.CommaFormat(param[1].data[2]) + '<br/>',
        //     '成交量' + ': ' + self.CommaFormat(param[0].data) + '<br/>',
        //   ].join('')
        // }
      },
    },
    dataZoom: [
      // {
      //   textStyle: {
      //     color: '#8392A5',
      //   },
      //   handleIcon:
      //     'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
      //   dataBackground: {
      //     areaStyle: {
      //       color: '#8392A5',
      //     },
      //     lineStyle: {
      //       opacity: 0.8,
      //       color: '#8392A5',
      //     },
      //   },
      //   brushSelect: true,
      // },
      {
        type: 'inside',
        startValue: 0,
        endValue: 30,
        // start: 50,
        // end: 100,
        zoomLock: true,
      },
      {
        show: true,
        type: 'slider',
        top: '90%',
        startValue: 0,
        endValue: 30,
        // start: 50,
        // end: 100,
        zoomLock: true,
        brushSelect: false,
      },
    ],
    xAxis: {
      data: showData.date,
    },
    yAxis: [
      {
        type: 'value',
        scale: true,
        name: 'mmHg',
        max: 210,
        min: 30,
        boundaryGap: [0.2, 0.2],
      },
      {
        type: 'value',
        scale: true,
        name: 'BPM',
        max: 210,
        min: 30,
        boundaryGap: [0.2, 0.2],
      },
    ],
    series: [
      {
        type: 'candlestick',
        data: showData.candleData,
        itemStyle: {
          color: '#a32326', // 'Red',
          color0: '#555755', // 'Gray',
          borderColor: undefined,
          borderColor0: undefined,
        },
        // itemStyle: {
        //   normal: {
        //     color: (item) => {
        //       return '#FFFF00'
        //     },
        //   },
        // },
      },
      {
        name: t('PR001.pulse'),
        type: 'line',
        data: showData.pulse,
        connectNulls: true,
        itemStyle: {
          color: 'Pink',
        },
      },
    ],
  })

  // const option = ref({
  //   tooltip: {
  //     trigger: 'axis',
  //     axisPointer: {
  //       animation: false,
  //       type: 'cross',
  //       lineStyle: {
  //         color: '#376df4',
  //         width: 2,
  //         opacity: 1,
  //       },
  //     },
  //     formatter: function (param) {
  //       if (param[0].seriesType == 'candlestick') {
  //         console.warn(param)
  //         return ''
  //         // return [
  //         //   '日期' + ': ' + param[0].name + '<hr size=1 style="margin: 3px 0">',
  //         //   '開盤價' + ': ' + param[0].data[1] + ' <br/> ',
  //         //   '最高價' + ': ' + param[0].data[4] + '<br/>',
  //         //   '最低價' + ': ' + param[0].data[3] + '<br/>',
  //         //   '收盤價' + ': ' + param[0].data[2] + '<br/>',
  //         //   '成交量' + ': ' + param[1].data + '<br/>',
  //         // ].join('')
  //       }
  //       // } else if (param[0].seriesType == 'bar') {
  //       //   return [
  //       //     '日期' + ': ' + param[1].name + '<hr size=1 style="margin: 3px 0">',
  //       //     '開盤價' + ': ' + self.CommaFormat(param[1].data[1]) + '<br/>',
  //       //     '最高價' + ': ' + self.CommaFormat(param[1].data[4]) + '<br/>',
  //       //     '最低價' + ': ' + self.CommaFormat(param[1].data[3]) + '<br/>',
  //       //     '收盤價' + ': ' + self.CommaFormat(param[1].data[2]) + '<br/>',
  //       //     '成交量' + ': ' + self.CommaFormat(param[0].data) + '<br/>',
  //       //   ].join('')
  //       // }
  //     },
  //   },
  //   dataZoom: [
  //     // {
  //     //   textStyle: {
  //     //     color: '#8392A5',
  //     //   },
  //     //   handleIcon:
  //     //     'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
  //     //   dataBackground: {
  //     //     areaStyle: {
  //     //       color: '#8392A5',
  //     //     },
  //     //     lineStyle: {
  //     //       opacity: 0.8,
  //     //       color: '#8392A5',
  //     //     },
  //     //   },
  //     //   brushSelect: true,
  //     // },
  //     {
  //       type: 'inside',
  //       startValue: 0,
  //       endValue: 30,
  //       // start: 50,
  //       // end: 100,
  //       zoomLock: true,
  //     },
  //     {
  //       show: true,
  //       type: 'slider',
  //       top: '90%',
  //       startValue: 0,
  //       endValue: 30,
  //       // start: 50,
  //       // end: 100,
  //       zoomLock: true,
  //       brushSelect: false,
  //     },
  //   ],
  //   xAxis: {
  //     data: [
  //       '2/1',
  //       '2/2',
  //       '2/3',
  //       '2/4',
  //       '2/5',
  //       '2/6',
  //       '2/7',
  //       '2/8',
  //       '2/9',
  //       '2/10',
  //       '2/11',
  //       '2/12',
  //       '2/13',
  //       '2/14',
  //       '2/15',
  //       '2/16',
  //       '2/17',
  //       '2/18',
  //       '2/19',
  //       '2/20',
  //       '2/21',
  //       '2/22',
  //       '2/23',
  //       '2/24',
  //       '2/25',
  //       '2/26',
  //       '2/27',
  //       '2/28',
  //       '3/1',
  //       '3/2',
  //       '3/3',
  //       '3/4',
  //       '3/5',
  //       '3/6',
  //       '3/7',
  //       '3/8',
  //       '3/9',
  //       '3/10',
  //       '3/11',
  //       '3/12',
  //       '3/13',
  //       '3/14',
  //       '3/15',
  //       '3/16',
  //       '3/17',
  //       '3/18',
  //       '3/19',
  //       '3/20',
  //       '3/21',
  //       '3/22',
  //       '3/23',
  //       '3/24',
  //       '3/25',
  //       '3/26',
  //       '3/27',
  //       '3/28',
  //       '3/29',
  //       '3/30',
  //       '3/31',
  //       '4/1',
  //       '4/2',
  //       '4/3',
  //       '4/4',
  //       '4/5',
  //       '4/6',
  //       '4/7',
  //       '4/8',
  //       '4/9',
  //       '4/10',
  //       '4/11',
  //       '4/12',
  //       '4/13',
  //       '4/14',
  //       '4/15',
  //       '4/16',
  //       '4/17',
  //       '4/18',
  //       '4/19',
  //       '4/20',
  //       '4/21',
  //       '4/22',
  //       '4/23',
  //       '4/24',
  //       '4/25',
  //       '4/26',
  //       '4/27',
  //       '4/28',
  //       '4/29',
  //       '4/30',
  //       '5/1',
  //     ],
  //   },
  //   yAxis: [
  //     {
  //       type: 'value',
  //       scale: true,
  //       name: 'mmHg',
  //       max: 210,
  //       min: 30,
  //       boundaryGap: [0.2, 0.2],
  //     },
  //     {
  //       type: 'value',
  //       scale: true,
  //       name: 'BPM',
  //       max: 210,
  //       min: 30,
  //       boundaryGap: [0.2, 0.2],
  //     },
  //   ],
  //   series: [
  //     {
  //       type: 'candlestick',
  //       data: [
  //         [88, 133, 143, 78],
  //         [],
  //         [],
  //         [],
  //         [82, 128, 138, 72],
  //         [81, 131, 145, 71],
  //         [],
  //         [],
  //         [80, 120, 130, 70],
  //         [137, 86, 147, 76],
  //         [],
  //         [133, 88, 143, 78],
  //         [129, 84, 139, 74],
  //         [131, 83, 141, 73],
  //         [140, 90, 150, 80],
  //         [],
  //         [],
  //         [],
  //         [127, 84, 137, 74],
  //         [122, 81, 132, 71],
  //         [144, 93, 154, 83],
  //         [138, 80, 148, 70],
  //         [129, 84, 139, 74],
  //         [133, 88, 143, 78],
  //         [],
  //         [],
  //         [142, 89, 152, 79],
  //         [125, 84, 135, 74],
  //         [128, 82, 138, 72],
  //         [131, 87, 141, 77],
  //         [133, 88, 143, 78],
  //         [161, 99, 171, 89],
  //         [159, 98, 169, 88],
  //         [],
  //         [],
  //         [144, 93, 154, 83],
  //         [138, 80, 148, 70],
  //         [168, 98, 178, 88],
  //         [169, 102, 179, 92],
  //         [172, 97, 182, 87],
  //         [],
  //         [],
  //         [159, 105, 169, 95],
  //         [177, 108, 187, 98],
  //         [],
  //         [],
  //         [],
  //         [],
  //         [],
  //         [],
  //         [],
  //         [139, 88, 149, 78],
  //         [155, 95, 165, 85],
  //         [194, 125, 204, 115],
  //         [198, 126, 208, 126],
  //         [204, 120, 214, 110],
  //         [209, 127, 219, 117],
  //         [188, 133, 198, 123],
  //         [198, 136, 208, 126],
  //         [194, 129, 204, 119],
  //         [],
  //         [],
  //         [],
  //         [],
  //         [131, 87, 141, 77],
  //         [143, 85, 153, 75],
  //         [161, 99, 171, 89],
  //         [160, 100, 170, 90],
  //         [166, 100, 176, 90],
  //         [200, 111, 210, 101],
  //         [199, 110, 209, 100],
  //         [208, 126, 218, 116],
  //         [190, 131, 200, 121],
  //         [],
  //         [203, 129, 213, 119],
  //         [206, 125, 216, 115],
  //         [123, 88, 133, 78],
  //         [133, 83, 143, 73],
  //         [135, 87, 145, 77],
  //         [130, 84, 140, 74],
  //         [122, 82, 132, 72],
  //         [133, 85, 143, 75],
  //         [146, 90, 156, 80],
  //         [138, 90, 148, 80],
  //         [122, 82, 132, 72],
  //         [139, 87, 149, 77],
  //         [134, 88, 144, 78],
  //         [198, 136, 208, 126],
  //         [204, 120, 214, 110],
  //         [209, 127, 219, 117],
  //       ],
  //       itemStyle: {
  //         color: 'Red',
  //         color0: 'Gray',
  //         borderColor: undefined,
  //         borderColor0: undefined,
  //       },
  //       // itemStyle: {
  //       //   normal: {
  //       //     color: (item) => {
  //       //       return '#FFFF00'
  //       //     },
  //       //   },
  //       // },
  //     },
  //     {
  //       name: '脈搏',
  //       type: 'line',
  //       data: [
  //         88,
  //         '-',
  //         '-',
  //         '-',
  //         98,
  //         101,
  //         '-',
  //         '-',
  //         77,
  //         87,
  //         '-',
  //         99,
  //         88,
  //         69,
  //         69,
  //         '-',
  //         '-',
  //         '-',
  //         81,
  //         82,
  //         99,
  //         65,
  //         88,
  //         99,
  //         '-',
  //         '-',
  //         88,
  //         88,
  //         98,
  //         98,
  //         80,
  //         99,
  //         98,
  //         '-',
  //         '-',
  //         99,
  //         65,
  //         98,
  //         99,
  //         88,
  //         '-',
  //         '-',
  //         77,
  //         92,
  //         '-',
  //         '-',
  //         '-',
  //         '-',
  //         '-',
  //         '-',
  //         '-',
  //         91,
  //         72,
  //         80,
  //         87,
  //         78,
  //         75,
  //         80,
  //         75,
  //         80,
  //         '-',
  //         '-',
  //         '-',
  //         '-',
  //         98,
  //         100,
  //         99,
  //         98,
  //         77,
  //         89,
  //         74,
  //         78,
  //         75,
  //         '-',
  //         74,
  //         79,
  //         88,
  //         87,
  //         100,
  //         99,
  //         98,
  //         101,
  //         100,
  //         85,
  //         77,
  //         88,
  //         98,
  //         87,
  //         78,
  //         75,
  //       ],
  //       connectNulls: true,
  //       itemStyle: {
  //         color: 'Pink',
  //       },
  //     },
  //   ],
  // })
</script>

<style lang="less" scoped>
  .normal-color {
    color: #575756;
  }
  .warning-color {
    color: #ffb600;
  }
  .abnormal-color {
    color: #8a2331;
  }
  .chart {
    height: 400px;
  }
  .pulse-icon {
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #ff8b9a;
  }
  .bloodpress-warn-icon {
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #a32326;
  }
  .bloodpress-normal-icon {
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #555755;
  }

  .detail-modal {
    width: 860px;

    .detail-table {
      margin-top: 30px;
      .cusTableTitle {
        display: flex;
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #5b5b5b;
        justify-content: center;
        height: 40px;
      }
      .cusTableUnit {
        //margin-top: 5px;
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: #7a7a7a;
        height: 15px;
      }
      .cusTableCell {
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #5b5b5b;
        justify-content: center;
      }
      .cusTableCellNoColor {
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        // color: #5b5b5b;
        justify-content: center;
      }
    }
  }
</style>
