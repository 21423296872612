import { render } from "./caseStatistics.vue?vue&type=template&id=5ece0373&scoped=true"
import script from "./caseStatistics.vue?vue&type=script&lang=js"
export * from "./caseStatistics.vue?vue&type=script&lang=js"

import "./caseStatistics.vue?vue&type=style&index=0&id=5ece0373&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-5ece0373"
/* hot reload */
if (module.hot) {
  script.__hmrId = "5ece0373"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5ece0373', script)) {
    api.reload('5ece0373', script)
  }
  
  module.hot.accept("./caseStatistics.vue?vue&type=template&id=5ece0373&scoped=true", () => {
    api.rerender('5ece0373', render)
  })

}

script.__file = "src/views/measurements/casePeriod/caseStatistics.vue"

export default script