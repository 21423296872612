<template>
  <div>
    <VChart
      ref="echartIns"
      class="chart"
      :option="option"
      :update-options="{ notMerge: true }"
      @click="onClickVChart"
    />

    <a-modal
      v-model:visible="isShowDetail"
      title=""
      class="detail-modal"
      width="860px"
      :footer="null"
      centered
    >
      <!-- <template #footer>
        <a-button class="btn-cancel-gray" @click="onClickDeleteCancel">
          {{ $t('LM005.cancel') }}
        </a-button>
        <a-button class="btn-confirm-green" @click="onClickDeleteOk">
          {{ $t('LM005.confirm') }}
        </a-button>
      </template>
    -->

      <a-table
        class="detail-table"
        :columns="detailColumns"
        :data-source="detailData"
        :rowKey="(record) => record.id"
        size="small"
      >
        <template #tmpRecordTimeTitle class="group1">
          <div class="cusTableTitle">{{ $t('PR001.recordTime') }}</div>
          <div class="cusTableUnit"></div>
        </template>
        <template #tmpRecordTime="{ text }">
          <span class="cusRecordTime">
            {{ convertTimeFormat(text) }}
          </span>
        </template>
        <template #tmpFastingTitle>
          <div class="cusTableTitle">{{ $t('PR001.fasting') }}</div>
          <div class="cusTableUnit">{{ getGlucoseUnit() }}</div>
        </template>
        <template #tmpBeforeMealTitle>
          <div class="cusTableTitle">{{ $t('PR001.beforeMeal') }}</div>
          <div class="cusTableUnit">{{ getGlucoseUnit() }}</div>
        </template>
        <template #tmpAfterMealTitle>
          <div class="cusTableTitle">{{ $t('PR001.afterMeal') }}</div>
          <div class="cusTableUnit">{{ getGlucoseUnit() }}</div>
        </template>
        <template #tmpBloodGlucoseFastingCell="{ record }">
          <span
            class="cusTableCell"
            :class="{
              'normal-color': record?.bloodGlucoseWarningLevel == 0,
              'warning-color': record?.bloodGlucoseWarningLevel == 1,
              'abnormal-color': record?.bloodGlucoseWarningLevel == 2,
            }"
          >
            {{ customBloodGlucoseFasting(record) }}
          </span>
        </template>
        <template #tmpBloodGlucoseBeforeCell="{ record }">
          <span
            class="cusTableCell"
            :class="{
              'normal-color': record?.bloodGlucoseWarningLevel == 0,
              'warning-color': record?.bloodGlucoseWarningLevel == 1,
              'abnormal-color': record?.bloodGlucoseWarningLevel == 2,
            }"
          >
            {{ customBloodGlucoseBefore(record) }}
          </span>
        </template>
        <template #tmpBloodGlucoseAfterCell="{ record }">
          <span
            class="cusTableCell"
            :class="{
              'normal-color': record?.bloodGlucoseWarningLevel == 0,
              'warning-color': record?.bloodGlucoseWarningLevel == 1,
              'abnormal-color': record?.bloodGlucoseWarningLevel == 2,
            }"
          >
            {{ customBloodGlucoseAfter(record) }}
          </span>
        </template>
        <template #tmpMathRoundCell="{ text }">
          <span class="cusTableCell">
            {{ roundDec(text, 0) }}
          </span>
        </template>
        <template #tmpArrCell="{ text }">
          <span class="cusTableCell">
            {{ convertArrValue(text) }}
          </span>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script setup>
  import { use } from 'echarts/core'
  import { CanvasRenderer } from 'echarts/renderers'
  import { CandlestickChart } from 'echarts/charts'
  import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    DataZoomComponent,
    GridComponent,
  } from 'echarts/components'
  import VChart, { THEME_KEY } from 'vue-echarts'
  import { ref, defineProps, watch, onMounted, computed } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { getMeasurementsById } from '@/api/measurement'
  import { useStore } from 'vuex'
  import logger from '@/utils/logger.js'
  import { roundDecimal } from '@/utils/pocketKnives'
  const dayjs = require('dayjs')

  use([
    CanvasRenderer,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    DataZoomComponent,
    GridComponent,
  ])

  const { t } = useI18n()
  const store = useStore()

  const echartIns = ref()

  const getMeasureThreshold = () =>
    store.dispatch('measureThreshold/getMeasureThreshold')

  const measureThreshold = computed(
    () => store.state.measureThreshold.measureThreshold
  )

  // 將 api 取得的資料依據日期歸戶
  let collData = {}

  const isShowDetail = ref(false)

  const props = defineProps({
    refresh: {
      type: Number,
      default: 0,
    },
    startTs: {
      type: Number,
      default: 100,
    },
    endTs: {
      type: Number,
      default: 10,
    },
    fid: {
      type: String,
      default: '1110',
    },
  })

  let measurements = []

  // const showData = {
  //   date: ['7/1', '7/2'],
  //   emptyGlu: [80,87],
  //   beforeGlu: [67,86],
  //   afterGlu: [87,77],
  //   emptyGluTip: [{
  //     mimGlu: 80,
  //     maxGlu: 87,
  //     showDate: "",
  //     dataType: "one", // avg
  //   }],
  //   beforeGluTip: [],
  //   afterGluTip: [],
  // }
  const showData = {
    date: [],
    emptyGlu: [],
    beforeGlu: [],
    afterGlu: [],
    emptyGluTip: [],
    beforeGluTip: [],
    afterGluTip: [],
  }

  let isOneDayData = false

  const detailData = ref([])

  const detailColumns = ref([
    {
      //title: '量測時間',
      dataIndex: 'measured',
      key: 'measured',
      width: '80px',
      // fixed: 'left',
      align: 'center',
      customHeaderCell: () => {
        return {
          style: {
            background: '#eefbfc',
          },
        }
      },
      slots: {
        title: 'tmpRecordTimeTitle',
        customRender: 'tmpRecordTime',
      },
    },
    {
      //title: '空腹血糖',
      dataIndex: 'fasting',
      key: 'fasting',
      slots: {
        title: 'tmpFastingTitle',
        customRender: 'tmpBloodGlucoseFastingCell',
      },
      width: '80px',
      align: 'center',
      customHeaderCell: () => {
        return {
          style: {
            background: '#eefbfc',
          },
        }
      },
    },
    {
      //title: '餐前血糖',
      dataIndex: 'beforeMeal',
      key: 'beforeMeal',
      slots: {
        title: 'tmpBeforeMealTitle',
        customRender: 'tmpBloodGlucoseBeforeCell',
      },
      width: '80px',
      align: 'center',
      customHeaderCell: () => {
        return {
          style: {
            background: '#eefbfc',
          },
        }
      },
    },
    {
      //title: '餐後血糖',
      dataIndex: 'afterMeal',
      key: 'afterMeal',
      slots: {
        title: 'tmpAfterMealTitle',
        customRender: 'tmpBloodGlucoseAfterCell',
      },
      width: '80px',
      align: 'center',
      customHeaderCell: () => {
        return {
          style: {
            background: '#eefbfc',
          },
        }
      },
    },
  ])

  const pagination = ref({
    //position: 'top',
    current: 1,
    pageSize: 10,
    total: 0,
    showTotal: getTotalCountString,
  })

  function getTotalCountString(total) {
    return this.$t('HR001.totalCount') + ` ${total}`
  }

  const getMeasurements = async () => {
    const postBody = {
      startTs: props.startTs,
      endTs: props.endTs,
      fid: props.fid,
      pageNum: 1,
      pageSize: 20000,
      type: 'glucose',
    }

    try {
      const data = await getMeasurementsById(postBody)
      if (data.ok === true && data?.data?.records) {
        measurements = data.data.records
      } else {
        measurements = []
      }
    } catch (e) {
      console.log('exception')
      console.log(e)
    }
  }

  const updateData = () => {
    isOneDayData = false
    showData.date = []
    showData.emptyGlu = []
    showData.beforeGlu = []
    showData.afterGlu = []
    showData.emptyGluTip = []
    showData.beforeGluTip = []
    showData.afterGluTip = []
    option.value.xAxis.data = showData.date
    option.value.series[0].data = showData.emptyGlu
    option.value.series[1].data = showData.beforeGlu
    option.value.series[2].data = showData.afterGlu

    if (measurements?.length <= 0) {
      return
    }
    if (!props.fid || props.startTs <= 0 || props.endTs <= 0) {
      return
    }

    // check same day
    const startDate = dayjs(props.startTs).format('YYYY/MM/DD')
    const endDate = dayjs(props.endTs).format('YYYY/MM/DD')

    if (startDate === endDate) {
      isOneDayData = true
      updateDataOneDay()
    } else {
      isOneDayData = false
      updateDataManyDay()
    }
    return
  }

  const updateDataManyDay = () => {
    const startDate = dayjs(props.startTs).format('YYYY/MM/DD')
    const endDate = dayjs(props.endTs).format('YYYY/MM/DD')

    // 1. 初始化圖表要用到的資料
    const mappingIndex = {}
    let startDay = dayjs(props.startTs).format('YYYY/MM/DD')
    const endDay = dayjs(props.endTs).format('YYYY/MM/DD')

    // 1.5 如果是少於 30 天，補到 30 天
    let checkDate = dayjs(props.endTs).subtract(30, 'days')
    if (checkDate.isBefore(dayjs(props.startTs))) {
      startDay = checkDate.format('YYYY/MM/DD')
    } else {
      checkDate = dayjs(props.startTs)
    }

    logger.debug(startDay)
    logger.debug(endDay)

    let count = 0

    showData.date.push(startDay)
    showData.emptyGlu.push('-')
    showData.beforeGlu.push('-')
    showData.afterGlu.push('-')
    showData.emptyGluTip.push({})
    showData.beforeGluTip.push({})
    showData.afterGluTip.push({})
    mappingIndex[startDay] = count
    let retriveDateObj = checkDate.add(1, 'day')
    let retriveDay = retriveDateObj.format('YYYY/MM/DD')

    const ProtectCount = 370 // 預防無窮迴圈
    while (retriveDay !== endDay) {
      count++
      showData.date.push(retriveDay)
      showData.emptyGlu.push('-')
      showData.beforeGlu.push('-')
      showData.afterGlu.push('-')
      showData.emptyGluTip.push({})
      showData.beforeGluTip.push({})
      showData.afterGluTip.push({})
      mappingIndex[retriveDay] = count
      if (count > ProtectCount) {
        break
      }

      retriveDateObj = retriveDateObj.add(1, 'day')
      retriveDay = retriveDateObj.format('YYYY/MM/DD')
    }
    count++
    showData.date.push(retriveDay)
    showData.emptyGlu.push('-')
    showData.beforeGlu.push('-')
    showData.afterGlu.push('-')
    showData.emptyGluTip.push({})
    showData.beforeGluTip.push({})
    showData.afterGluTip.push({})
    mappingIndex[retriveDay] = count
    // console.log(showData)

    // 2. 整理 api 取得的資料
    collData = {}
    for (let record of measurements) {
      const measuredDay = dayjs(record.measured).format('YYYY/MM/DD')
      if (!collData[measuredDay]) {
        collData[measuredDay] = []
      }
      collData[measuredDay].push(record)
    }
    // console.log('colData')
    // console.log(collData)

    // 3. 計算資料
    for (const key in collData) {
      let avgEmptyGlu = 0 // 平均空腹血糖
      let avgBeforeGlu = 0 // 平均飯前血糖
      let avgAfterGlu = 0 // 平均飯後血糖
      let countEmptyGlu = 0
      let countBeforeGlu = 0
      let countAfterGlu = 0
      let minEmptyGlu = 0 //
      let maxEmptyGlu = 0 //
      let minBeforeGlu = 0 //
      let maxBeforeGlu = 0 //
      let minAfterGlu = 0 //
      let maxAfterGlu = 0 //
      for (const record of collData[key]) {
        if (record.meal && record.meal === 'Fasting' && record.bloodGlucose) {
          if (minEmptyGlu === 0 || record.bloodGlucose <= minEmptyGlu) {
            minEmptyGlu = record.bloodGlucose
          }
          if (record.bloodGlucose >= maxEmptyGlu) {
            maxEmptyGlu = record.bloodGlucose
          }
          avgEmptyGlu += record.bloodGlucose
          countEmptyGlu++
        }
        if (
          record.meal &&
          record.meal === 'Before Meal' &&
          record.bloodGlucose
        ) {
          if (minBeforeGlu === 0 || record.bloodGlucose <= minBeforeGlu) {
            minBeforeGlu = record.bloodGlucose
          }
          if (record.bloodGlucose >= maxBeforeGlu) {
            maxBeforeGlu = record.bloodGlucose
          }
          avgBeforeGlu += record.bloodGlucose
          countBeforeGlu++
        }
        if (
          record.meal &&
          record.meal === 'After Meal' &&
          record.bloodGlucose
        ) {
          if (minAfterGlu === 0 || record.bloodGlucose <= minAfterGlu) {
            minAfterGlu = record.bloodGlucose
          }
          if (record.bloodGlucose >= maxAfterGlu) {
            maxAfterGlu = record.bloodGlucose
          }
          avgAfterGlu += record.bloodGlucose
          countAfterGlu++
        }
      }
      const idx = mappingIndex[key]
      if (avgEmptyGlu > 0 && countEmptyGlu > 0) {
        avgEmptyGlu = avgEmptyGlu / countEmptyGlu
        showData.emptyGlu[idx] = avgEmptyGlu
        let showDate = ''
        let dataType = 'avg'
        if (countEmptyGlu === 1) {
          // 該天只有一筆
          showDate = dayjs(collData[key][0].measured).format('YYYY/MM/DD HH:mm')
          dataType = 'one'
        } else {
          // 該天有多筆
          showDate = dayjs(collData[key][0].measured).format('YYYY/MM/DD')
          dataType = 'avg'
        }
        showData.emptyGluTip[idx] = {
          minGlu: minEmptyGlu,
          maxGlu: maxEmptyGlu,
          showDate,
          dataType,
        }
      }
      if (avgBeforeGlu > 0 && countBeforeGlu > 0) {
        avgBeforeGlu = avgBeforeGlu / countBeforeGlu
        showData.beforeGlu[idx] = avgBeforeGlu
        let showDate = ''
        let dataType = 'avg'
        if (countBeforeGlu === 1) {
          // 該天只有一筆
          showDate = dayjs(collData[key][0].measured).format('YYYY/MM/DD HH:mm')
          dataType = 'one'
        } else {
          // 該天有多筆
          showDate = dayjs(collData[key][0].measured).format('YYYY/MM/DD')
          dataType = 'avg'
        }
        showData.beforeGluTip[idx] = {
          minGlu: minBeforeGlu,
          maxGlu: maxBeforeGlu,
          showDate,
          dataType,
        }
      }
      if (avgAfterGlu > 0 && countAfterGlu > 0) {
        avgAfterGlu = avgAfterGlu / countAfterGlu
        showData.afterGlu[idx] = avgAfterGlu
        let showDate = ''
        let dataType = 'avg'
        if (countAfterGlu === 1) {
          // 該天只有一筆
          showDate = dayjs(collData[key][0].measured).format('YYYY/MM/DD HH:mm')
          dataType = 'one'
        } else {
          // 該天有多筆
          showDate = dayjs(collData[key][0].measured).format('YYYY/MM/DD')
          dataType = 'avg'
        }
        showData.afterGluTip[idx] = {
          minGlu: minAfterGlu,
          maxGlu: maxAfterGlu,
          showDate,
          dataType,
        }
      }
    }

    // 4. 塞資料到圖
    // option.value.xAxis.data = showData.date
    // console.log('showData')
    // console.log(showData)
    showData.date = showData.date.map(function (value, index, array) {
      let prefix = value.substring(0, 5) // 提取前五个字符
      if (prefix.length === 5) {
        return value.substring(5) // 去除前五个字符
      }
      return value
    })
    option.value.xAxis.data = showData.date
    option.value.series[0].data = showData.emptyGlu
    option.value.series[1].data = showData.beforeGlu
    option.value.series[2].data = showData.afterGlu

    let name = 'mg/dl'
    let defaultMax = 210
    if (measureThreshold.value?.bloodGlucose?.unit === 'mmol/L') {
      name = 'mmol/L'
      defaultMax = 10
    }

    if (isGlucoseMoreThan210()) {
      option.value.yAxis = [
        {
          type: 'value',
          scale: true,
          name: name,
          // max: 210,
          min: 0,
          boundaryGap: [0.2, 0.2],
        },
      ]
    } else {
      option.value.yAxis = [
        {
          type: 'value',
          scale: true,
          name: name,
          max: defaultMax,
          min: 0,
          boundaryGap: [0.2, 0.2],
          // splitNumber: 7, // 分成七段
        },
      ]
    }
  }

  const updateDataOneDay = () => {
    // 1. 初始化圖表要用到的資料
    // logger.debug(startDay)
    // logger.debug(endDay)

    for (let count = 0; count < 24; count++) {
      showData.date.push(count)
      showData.emptyGlu.push('-')
      showData.beforeGlu.push('-')
      showData.afterGlu.push('-')
      showData.emptyGluTip.push({})
      showData.beforeGluTip.push({})
      showData.afterGluTip.push({})
    }
    // console.log(showData)

    // 2. 整理 api 取得的資料
    collData = new Array(24)
    for (var i = 0; i < collData.length; i++) collData[i] = []

    // console.log(collData)
    for (let record of measurements) {
      const measuredHour = dayjs(record.measured).hour()
      collData[measuredHour].push(record)
    }
    // console.log('collData')
    // console.log(collData)

    // 3. 計算資料
    for (let idx = 0; idx < collData.length; idx++) {
      let avgEmptyGlu = 0 // 平均空腹血糖
      let avgBeforeGlu = 0 // 平均飯前血糖
      let avgAfterGlu = 0 // 平均飯後血糖
      let countEmptyGlu = 0
      let countBeforeGlu = 0
      let countAfterGlu = 0
      let minEmptyGlu = 0 //
      let maxEmptyGlu = 0 //
      let minBeforeGlu = 0 //
      let maxBeforeGlu = 0 //
      let minAfterGlu = 0 //
      let maxAfterGlu = 0 //
      for (const record of collData[idx]) {
        if (record.meal && record.meal === 'Fasting' && record.bloodGlucose) {
          if (minEmptyGlu === 0 || record.bloodGlucose <= minEmptyGlu) {
            minEmptyGlu = record.bloodGlucose
          }
          if (record.bloodGlucose >= maxEmptyGlu) {
            maxEmptyGlu = record.bloodGlucose
          }
          avgEmptyGlu += record.bloodGlucose
          countEmptyGlu++
        }
        if (
          record.meal &&
          record.meal === 'Before Meal' &&
          record.bloodGlucose
        ) {
          if (minBeforeGlu === 0 || record.bloodGlucose <= minBeforeGlu) {
            minBeforeGlu = record.bloodGlucose
          }
          if (record.bloodGlucose >= maxBeforeGlu) {
            maxBeforeGlu = record.bloodGlucose
          }
          avgBeforeGlu += record.bloodGlucose
          countBeforeGlu++
        }
        if (
          record.meal &&
          record.meal === 'After Meal' &&
          record.bloodGlucose
        ) {
          if (minAfterGlu === 0 || record.bloodGlucose <= minAfterGlu) {
            minAfterGlu = record.bloodGlucose
          }
          if (record.bloodGlucose >= maxAfterGlu) {
            maxAfterGlu = record.bloodGlucose
          }
          avgAfterGlu += record.bloodGlucose
          countAfterGlu++
        }
      }
      if (avgEmptyGlu > 0 && countEmptyGlu > 0) {
        avgEmptyGlu = avgEmptyGlu / countEmptyGlu
        showData.emptyGlu[idx] = avgEmptyGlu
        let showDate = ''
        let dataType = 'avg'
        if (countEmptyGlu === 1) {
          // 該天只有一筆
          showDate = dayjs(collData[idx][0].measured).format('YYYY/MM/DD HH:mm')
          dataType = 'one'
        } else {
          // 該天有多筆
          showDate = dayjs(collData[idx][0].measured).format('YYYY/MM/DD')
          dataType = 'avg'
        }
        showData.emptyGluTip[idx] = {
          minGlu: minEmptyGlu,
          maxGlu: maxEmptyGlu,
          showDate,
          dataType,
        }
      }
      if (avgBeforeGlu > 0 && countBeforeGlu > 0) {
        avgBeforeGlu = avgBeforeGlu / countBeforeGlu
        showData.beforeGlu[idx] = avgBeforeGlu
        let showDate = ''
        let dataType = 'avg'
        if (countBeforeGlu === 1) {
          // 該天只有一筆
          showDate = dayjs(collData[idx][0].measured).format('YYYY/MM/DD HH:mm')
          dataType = 'one'
        } else {
          // 該天有多筆
          showDate = dayjs(collData[idx][0].measured).format('YYYY/MM/DD')
          dataType = 'avg'
        }
        showData.beforeGluTip[idx] = {
          minGlu: minBeforeGlu,
          maxGlu: maxBeforeGlu,
          showDate,
          dataType,
        }
      }
      if (avgAfterGlu > 0 && countAfterGlu > 0) {
        avgAfterGlu = avgAfterGlu / countAfterGlu
        showData.afterGlu[idx] = avgAfterGlu
        let showDate = ''
        let dataType = 'avg'
        if (countAfterGlu === 1) {
          // 該天只有一筆
          showDate = dayjs(collData[idx][0].measured).format('YYYY/MM/DD HH:mm')
          dataType = 'one'
        } else {
          // 該天有多筆
          showDate = dayjs(collData[idx][0].measured).format('YYYY/MM/DD')
          dataType = 'avg'
        }
        showData.afterGluTip[idx] = {
          minGlu: minAfterGlu,
          maxGlu: maxAfterGlu,
          showDate,
          dataType,
        }
      }
    }

    // 4. 塞資料到圖
    option.value.xAxis.data = showData.date
    option.value.series[0].data = showData.emptyGlu
    option.value.series[1].data = showData.beforeGlu
    option.value.series[2].data = showData.afterGlu

    let name = 'mg/dl'
    let defaultMax = 210
    if (measureThreshold.value?.bloodGlucose?.unit === 'mmol/L') {
      name = 'mmol/L'
      defaultMax = 10
    }

    if (isGlucoseMoreThan210()) {
      option.value.yAxis = [
        {
          type: 'value',
          scale: true,
          name: name,
          // max: 210,
          min: 0,
          boundaryGap: [0.2, 0.2],
        },
      ]
    } else {
      option.value.yAxis = [
        {
          type: 'value',
          scale: true,
          name: name,
          max: defaultMax,
          min: 0,
          boundaryGap: [0.2, 0.2],
          // splitNumber: 7, // 分成七段
        },
      ]
    }
  }

  function onClickVChart(inVal) {
    // console.log(inVal)
    // console.log(collData)

    const showDataIdx = inVal.dataIndex
    let clickDate = ''
    let glucoseType = ''
    if (inVal.seriesType == 'line' && inVal.seriesName == t('PR001.fasting')) {
      clickDate = showData.emptyGluTip[showDataIdx].showDate
      glucoseType = 'Fasting'
    } else if (
      inVal.seriesType == 'line' &&
      inVal.seriesName == t('PR001.beforeMeal')
    ) {
      clickDate = showData.beforeGluTip[showDataIdx].showDate
      glucoseType = 'Before Meal'
    } else if (
      inVal.seriesType == 'line' &&
      inVal.seriesName == t('PR001.afterMeal')
    ) {
      clickDate = showData.afterGluTip[showDataIdx].showDate
      glucoseType = 'After Meal'
    } else {
      return
    }
    if (!clickDate) {
      return
    }

    if (isOneDayData) {
      const index = inVal.dataIndex || 0
      detailData.value = collData[index].filter((item) => {
        return item.meal === glucoseType
      })
    } else {
      clickDate = clickDate.substring(0, 10)
      detailData.value = collData[clickDate].filter((item) => {
        return item.meal === glucoseType
      })
    }

    // console.log(detailData.value)
    isShowDetail.value = true
  }

  function roundDec(val, precision) {
    let convertNumber = val
    if (typeof val === 'string') {
      convertNumber = Number(val)
      if (isNaN(convertNumber)) {
        return 0
      }
    }
    return roundDecimal(convertNumber, precision)
  }

  function convertArrValue(text) {
    if (text === 0 || text === '0') {
      return '-'
    } else {
      return '✓'
    }
  }
  onMounted(async () => {
    if (props.fid && props.startTs > 0 && props.endTs > 0) {
      await getMeasurements()
      updateData()
    }
    getMeasureThreshold()
  })

  watch(
    () => props.refresh,
    async (current, prev) => {
      if (props.fid && props.startTs > 0 && props.endTs > 0) {
        await getMeasurements()
        updateData()
      }
    }
  )

  function convertTimeFormat(textTs) {
    if (textTs) {
      return dayjs(textTs).format('YYYY/MM/DD')
    } else {
      return ''
    }
  }

  function customBloodGlucoseFasting(record) {
    // console.log(record)
    if (record && record.meal && record.meal === 'Fasting') {
      return roundDecimal(record.bloodGlucose, 1)
    }
    return ''
  }

  function customBloodGlucoseBefore(record) {
    // console.log(record)
    if (record && record.meal && record.meal === 'Before Meal') {
      return roundDecimal(record.bloodGlucose, 1)
    }
    return ''
  }

  function customBloodGlucoseAfter(record) {
    // console.log(record)
    if (record && record.meal && record.meal === 'After Meal') {
      return roundDecimal(record.bloodGlucose, 1)
    }
    return ''
  }

  function getGlucoseUnit() {
    if (measureThreshold.value?.bloodGlucose?.unit === 'mmol/L') {
      return t('MS002.mmol/L')
    } else {
      // 公制
      return t('MS002.mg/dL')
    }
  }

  function isGlucoseMoreThan210() {
    if (measureThreshold.value?.bloodGlucose?.unit === 'mmol/L') {
      if (showData.emptyGlu.some((measurement) => measurement > 10) === true) {
        return true
      }
      if (showData.beforeGlu.some((measurement) => measurement > 10) === true) {
        return true
      }
      if (showData.afterGlu.some((measurement) => measurement > 10) === true) {
        return true
      }
    } else {
      if (showData.emptyGlu.some((measurement) => measurement > 210) === true) {
        return true
      }
      if (
        showData.beforeGlu.some((measurement) => measurement > 210) === true
      ) {
        return true
      }
      if (showData.afterGlu.some((measurement) => measurement > 210) === true) {
        return true
      }
    }

    return false
  }

  function roundAvg(inVal) {
    if (measureThreshold.value?.bloodGlucose?.unit === 'mmol/L') {
      return roundDecimal(inVal, 1)
    } else {
      return roundDecimal(inVal, 0)
    }
  }

  function getUnitName() {
    if (measureThreshold.value?.bloodGlucose?.unit === 'mmol/L') {
      return t('MS002.mmol/L')
    } else {
      return t('MS002.mg/dL')
    }
  }

  const option = ref({
    legend: {},
    tooltip: {
      // trigger: 'axis',
      trigger: 'item',
      axisPointer: {
        animation: false,
        type: 'cross',
        lineStyle: {
          color: '#376df4',
          width: 2,
          opacity: 1,
        },
      },
      formatter: function (param) {
        try {
          // console.log(param)
          let lineData = param
          if (param.length > 0) {
            lineData = param[0]
          }

          if (lineData.seriesType !== 'line') {
            return ''
          }

          const title = lineData?.seriesName
          let maxGlu = 0
          let minGlu = 0
          const showUnit = getUnitName()
          let showDate = ''
          let dataType = 'one'

          const showDataIdx = lineData.dataIndex
          // const showDataIdx = showData.date.indexOf(lineData?.name)
          // console.log(lineData.dataIndex)
          // console.log(showDataIdx)
          // if (!showDataIdx) {
          //   return ''
          // }

          if (
            lineData.seriesType == 'line' &&
            lineData.seriesName == t('PR001.fasting')
          ) {
            if (!lineData.data) {
              return ''
            }
            showDate = showData.emptyGluTip[showDataIdx].showDate
            dataType = showData.emptyGluTip[showDataIdx].dataType
            if (showData.emptyGluTip[showDataIdx].dataType === 'avg') {
              maxGlu = showData.emptyGluTip[showDataIdx].maxGlu
              minGlu = showData.emptyGluTip[showDataIdx].minGlu
            }
          } else if (
            lineData.seriesType == 'line' &&
            lineData.seriesName == t('PR001.beforeMeal')
          ) {
            if (!lineData.data) {
              return ''
            }
            showDate = showData.beforeGluTip[showDataIdx].showDate
            dataType = showData.beforeGluTip[showDataIdx].dataType
            if (showData.beforeGluTip[showDataIdx].dataType === 'avg') {
              maxGlu = showData.beforeGluTip[showDataIdx].maxGlu
              minGlu = showData.beforeGluTip[showDataIdx].minGlu
            }
          } else if (
            lineData.seriesType == 'line' &&
            lineData.seriesName == t('PR001.afterMeal')
          ) {
            if (!lineData.data) {
              return ''
            }
            showDate = showData.afterGluTip[showDataIdx].showDate
            dataType = showData.afterGluTip[showDataIdx].dataType
            if (showData.afterGluTip[showDataIdx].dataType === 'avg') {
              maxGlu = showData.afterGluTip[showDataIdx].maxGlu
              minGlu = showData.afterGluTip[showDataIdx].minGlu
            }
          }
          if (dataType === 'avg') {
            return [
              `${title}<br/> `,
              t('PR001.avgBloodGlucose') +
                `: ${roundAvg(lineData?.data)} <br/> `,
              t('PR001.highestBloodGlucose') + `: ${maxGlu} <br/> `,
              t('PR001.lowestBloodGlucose') + `: ${minGlu} <br/> `,
              `<hr size=1 style="margin: 3px 0">`,
              t('PR001.unit') + `: ${showUnit}<br/>  `,
              t('PR001.date') + `: ${showDate} `,
            ].join('')
          } else if (dataType === 'one') {
            return [
              `${title}: ${lineData?.data}<br/> `,
              `<hr size=1 style="margin: 3px 0">`,
              t('PR001.unit') + `: ${showUnit}<br/>  `,
              t('PR001.date') + `: ${showDate} `,
            ].join('')
          }
        } catch (e) {
          console.log(e)
          return ''
        }
      },
    },
    dataZoom: [
      // {
      //   textStyle: {
      //     color: '#8392A5',
      //   },
      //   handleIcon:
      //     'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
      //   dataBackground: {
      //     areaStyle: {
      //       color: '#8392A5',
      //     },
      //     lineStyle: {
      //       opacity: 0.8,
      //       color: '#8392A5',
      //     },
      //   },
      //   brushSelect: true,
      // },
      {
        type: 'inside',
        startValue: 0,
        endValue: 30,
        // start: 50,
        // end: 100,
        zoomLock: true,
      },
      {
        show: true,
        type: 'slider',
        top: '90%',
        startValue: 0,
        endValue: 30,
        // start: 50,
        // end: 100,
        zoomLock: true,
        brushSelect: false,
      },
    ],
    xAxis: {
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        scale: true,
        name: 'mg/dl',
        max: 210,
        min: 0,
        boundaryGap: [0.2, 0.2],
        splitNumber: 7, // 分成七段
      },
    ],
    series: [
      {
        name: t('PR001.fasting'),
        type: 'line',
        emphasis: {
          focus: 'series',
        },
        data: [],
        connectNulls: true,
        itemStyle: {
          color: '#219653',
        },
      },
      {
        name: t('PR001.beforeMeal'),
        type: 'line',
        data: [],
        connectNulls: true,
        itemStyle: {
          color: '#2F80ED',
        },
      },
      {
        name: t('PR001.afterMeal'),
        type: 'line',
        data: [],
        connectNulls: true,
        itemStyle: {
          color: '#F2994A',
        },
      },
    ],
  })
</script>

<style lang="less" scoped>
  .normal-color {
    color: #575756;
  }
  .warning-color {
    color: #ffb600;
  }
  .abnormal-color {
    color: #8a2331;
  }

  .chart {
    height: 400px;
  }

  .detail-table {
    padding-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 25px;

    .cusRecordTime {
      font-family: Arial;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #2e2e2e;
      justify-content: center;
    }

    .cusTableTitle {
      display: flex;
      font-family: Noto Sans TC;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #5b5b5b;
      justify-content: center;
      height: 40px;
    }
    .cusTableUnit {
      //margin-top: 5px;
      font-family: Noto Sans TC;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #7a7a7a;
      height: 15px;
    }
    .cusTableCell {
      font-family: Noto Sans TC;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      // color: #5b5b5b;
      justify-content: center;
    }
  }
</style>
